import { IOnboardSorting } from "interfaces/OnbaordingInterface";
import React from "react";

const OnboardSorting: React.FC<IOnboardSorting> = ({
  open,
  setOpen,
  children,
  name,
}) => {
  return (
    <>
      {!open && (
        <div
          className="fixed right-0 z-10 top-[45%] cursor-pointer rounded-l-xl bg-background h-[120px] w-[40px] roat"
          onClick={() => setOpen(!open)}
        >
          <span className="absolute h-auto p-2 text-sm transform -rotate-90 text-headerBlue w-max top-[40px] -right-[30px]">
            {name}
          </span>
        </div>
      )}

      <main
        className={
          " fixed overflow-hidden z-10 bg-transparent inset-0 transform ease-in-out" +
          (open
            ? " transition-opacity opacity-100 duration-500 translate-x-0  "
            : " transition-all delay-500 opacity-0 translate-x-full  ")
        }
      >
        <section
          className={
            "w-[280px] md:w-[350px] max-w-lg right-0 absolute bg-background h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  " +
            (open ? " translate-x-0 " : " translate-x-full ")
          }
        >
          <div
            className="fixed z-10 top-[50%] -left-[65px] cursor-pointer justify-center flex "
            onClick={() => setOpen(!open)}
          >
            <div className="p-2 text-sm -rotate-90 rounded-t-xl bg-background text-headerBlue">
              {name}
            </div>
          </div>

          <div className="p-5">
            <div className="py-4 text-xl font-bold leading-6 text-headerBlue">
              Unsorted
            </div>
            <span className="text-xs flex flex-col gap-4">
              <span>Drag and drop the career into one of the three categories below.</span>
              <span>Hover and release!</span>
              Don't worry, you can change this later.
            </span>

            <div> {children}</div>
          </div>
        </section>
        <section
          className="w-screen h-full cursor-pointer "
          onClick={() => {
            setOpen(false);
          }}
        ></section>
      </main>
    </>
  );
};

export default OnboardSorting;
