import CareerItem from "../../components/career-item/career-item.component";
import Tabs from "components/tabs/tabs.component";
import { ITabItem } from "components/tabs/tabs.interface";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loading from "components/loading/loading.component";
import { CareerTypeEnum } from "enums/glimps.enums";
import {
  getEndUserCareer,
  getEndUserExportVoice,
  getPreviewSideData,
  // unlockForDeeperDive,
} from "services/deeper-dive.service";
import { IEndUserCareer } from "interfaces/CareerInterface";
import GlimpsePopup from "components/popup/popup.component";
import DeepDiveSideData from "./DeepDiveSideData";
import VideoStreaming from "components/video-streaming-onboard/video-streaming.component";
import { unlockNextGlimpse } from "services/on-board";
import { displayError, displayWarning } from "components/Toast/toast.component";

const tabsList: ITabItem[] = [
  {
    name: "Preview",
  },
  {
    name: "Deeper Dive",
  },
];
const staticCareersListBlur = [
  { icon_url: "/assets/icons/boy_with_glass.svg", name: "Developer" },
  { icon_url: "/assets/icons/LAdy_Icon.svg", name: "Nurse" },
  { icon_url: "/assets/icons/boy_with_glass.svg", name: "Developer" },
  { icon_url: "/assets/icons/boy_with_glass.svg", name: "Developer" },
];

const UserCareersScreen: React.FC = () => {
  let tabDetails = new URLSearchParams(window.location.search).get("tab");
  const [popup, setPopup] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<CareerTypeEnum>(
    CareerTypeEnum.preview
  );
  const [prompt, setPrompt] = useState<boolean>(true);
  const [career, setCareer] = useState<IEndUserCareer>();
  const navigate = useNavigate();
  const {
    data: deeperDiveList,
    status,
    isRefetching,
    refetch: refetchList,
  } = useQuery({
    queryKey: ["getAllCareer"],
    queryFn: () => getEndUserCareer(activeTab),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (tabDetails) {
      setActiveTab(tabDetails as CareerTypeEnum);
    }
  }, [tabDetails]);

  useEffect(() => {
    let dialogStatus = localStorage.getItem("DeeperDiveDialog");
    if (dialogStatus === null) {
      setPrompt(true);
    } else if (dialogStatus === "true") {
      setPrompt(true);
    } else {
      setPrompt(false);
    }
  }, []);

  const { data: expertVoice, refetch: refetchExpertVoice } = useQuery({
    queryKey: ["getEndUserExportVoice"],
    queryFn: () => getEndUserExportVoice(career?.id, career?.cover_video_id),
    enabled: false,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  // const unlockDeeperDiveRes = useMutation({
  //   mutationFn: (id: string) => unlockForDeeperDive(id),
  //   retry: 0,
  //   onSuccess: (res) => {
  //     navigate(("/user-careers/user-deeperdive/" + res.id) as string);
  //   },
  // });

  const tabChanged = async (
    e: React.MouseEvent<HTMLDivElement>,
    index: number
  ) => {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      `?tab=${index === 0 ? CareerTypeEnum.preview : CareerTypeEnum.deepdive}`;
    window.history.pushState({ path: newurl }, "", newurl);
    await setActiveTab(
      index === 0 ? CareerTypeEnum.preview : CareerTypeEnum.deepdive
    );
    refetchList();
  };
  const onClosePrompt = () => {
    localStorage.setItem("DeeperDiveDialog", "false");
    setPrompt(false);
  };

  const startPreview = async (data: IEndUserCareer) => {
    await setCareer(data);
    refetchExpertVoice().then(() => {
      setPopup(true);
    });
  };

  const startDeeperDive = (item: IEndUserCareer) => {
    if (item?.is_completed) {
      navigate("/user-careers/careers/career-insights/" + item.career_id);
    } else {
      navigate("/user-careers/deeper-dive/" + item.id);
    }
  };

  const { data: previewData } = useQuery({
    queryKey: ["previewData"],
    queryFn: () => getPreviewSideData(),
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const unlockOnBoard = useMutation({
    mutationKey: ["UnlockNextEight"],
    mutationFn: () => unlockNextGlimpse(),
    retry: 0,
    onSuccess: async (res) => {
      if (res.unlock_status) {
        navigate("/user-careers/unlock-next/start");
      } else {
        displayError(res.unlock_message);
      }
    },
    onError: (err: Error) => {
      console.error(err);
      displayWarning("Unable to unlock careers");
    },
  });

  return (
    <>
      <div className="grid order-1 grid-cols-1 lg:flex lg:flex-row lg:justify-between lg:order-2">
        <div className="order-2 lg:order-1 ">
          {prompt && activeTab === CareerTypeEnum.deepdive && (
            <>
              <div className=" flex max-w-[554px] min-w-[340px] min-h-[115px] lg:h-[60px] p-4 order-2 lg:order-1">
                <div className="relative bg-white rounded-lg shadow">
                  <button
                    type="button"
                    className="absolute inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg end-0 hover:bg-gray-200 hover:text-gray-900 ms-auto "
                    onClick={onClosePrompt}
                  >
                    <svg
                      width={30}
                      height={30}
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 22.5C26.6421 18.3579 26.6421 11.6421 22.5 7.5C18.3579 3.35786 11.6421 3.35786 7.5 7.5C3.35786 11.6421 3.35786 18.3579 7.5 22.5C11.6421 26.6421 18.3579 26.6421 22.5 22.5Z"
                        fill="#404040"
                      />
                      <path
                        d="M10.1721 19.8279C9.94265 19.5985 9.94265 19.2265 10.1721 18.9956L18.9956 10.1721C19.225 9.94265 19.5985 9.94265 19.8279 10.1721C20.0574 10.4015 20.0574 10.775 19.8279 11.0044L11.0044 19.8279C10.775 20.0574 10.4029 20.0574 10.1721 19.8279Z"
                        fill="white"
                      />
                      <path
                        d="M18.9956 19.8279L10.1721 11.0044C9.94265 10.775 9.94265 10.4015 10.1721 10.1721C10.4015 9.94265 10.775 9.94265 11.0044 10.1721L19.8279 18.9956C20.0574 19.225 20.0574 19.5971 19.8279 19.8279C19.5985 20.0574 19.225 20.0574 18.9956 19.8279Z"
                        fill="white"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                  <div className="text-start ">
                    <div className="p-4 text-sm font-bold text-[#707071]">
                      Click into each career card for a deeper dive. Learn more
                      about the career and its purpose, day in the life
                      information, education requirements, salary potential, and
                      more!
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <Tabs
            items={tabsList}
            onClick={tabChanged}
            activeIndex={activeTab === CareerTypeEnum.preview ? 0 : 1}
            className="flex"
          />
          <div className="w-full h-full ">
            {activeTab === CareerTypeEnum.preview && (
              <>
                <div className="p-2 sm:p-4 ">
                  <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
                    Next 8 Careers Glimpses
                  </div>
                  <div className="relative">
                    <div className="flex items-center py-4 overflow-hidden h-[144px]">
                      {staticCareersListBlur?.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="h-[144px] pt-4 px-2 first:pl-6 blur-sm bg-gradient-to-r from-background backdrop-blur-sm rounded-xl"
                          >
                            <CareerItem
                              id={" "}
                              icon_url={item.icon_url}
                              status={""}
                              name={item.name}
                              pressed={() => { }}
                            />
                          </div>
                        );
                      })}
                      <div className="absolute w-full h-full max-w-2xl rounded-xl bg-gradient-to-l from-background  via-background backdrop-blur-sm lg:min-w-[200px]">
                        <div
                          className="flex flex-col items-center justify-end h-full gap-2 p-8 text-center xl:flex-row lg:p-12 min-w-12 "
                          onClick={() => { }}
                        >
                          <div className="font-bold text-headerBlack text-md lg:text-xl">
                            {previewData?.is_available_careers ? (
                              "Unlock your next 8 Glimpes"
                            ) : (
                              <span className="text-xl font-bold">
                                More careers coming soon!
                              </span>
                            )}
                          </div>
                          {previewData?.is_available_careers && (
                            <div className="px-10">
                              <button
                                onClick={() => {
                                  unlockOnBoard.mutateAsync();
                                }}
                                disabled={!previewData?.is_available_careers}
                                className="text-white text-center bg-green gap-3 pl-1.5 pt-2.5 pr-1.5 pb-2.5 w-[121px] rounded-full text-sm font-normal leading-5 capitalize"
                              >
                                {previewData?.is_available_careers}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <GlimpsePopup
                  title={""}
                  popup={popup}
                  showCross={false}
                  onClose={() => {
                    setPopup(false);
                  }}
                  customClass="flex justify-center overflow-hidden pl-[20px] "
                >
                  <VideoStreaming
                    popupClass={"pr-[20px] py-[20px]"}
                    header={{
                      title: expertVoice?.career.name as string,
                      subtitle: expertVoice?.career.superpower as string,
                      image: expertVoice?.career?.icon_url as string,
                    }}
                    video_url={expertVoice?.expert_voice.voice_url as string}
                    key={`OnBoardingVideo`}
                    thumbnail={
                      expertVoice?.expert_voice.voice_thumbnail as string
                    }
                    onBuffer={() => { }}
                    onStart={() => {
                      // sendJsonMessage({
                      //   action: "video_progress",
                      //   progress: {
                      //     id: onBoardData.results[0].cover_video.id,
                      //     progress: 0,
                      //     status: "started",
                      //   },
                      // });
                    }}
                    onProgress={(data) => {
                      // setVideoCurrentTime(data.playedSeconds);
                      // sendJsonMessage({
                      //   action: "video_progress",
                      //   progress: {
                      //     id: onBoardData.results[0].cover_video.id,
                      //     progress: data.playedSeconds,
                      //     status: "watching",
                      //   },
                      // });
                    }}
                    onPaused={() => { }}
                    onEnd={() => {
                      // sendJsonMessage({
                      //   action: "video_progress",
                      //   progress: {
                      //     id: onBoardData.results[0].cover_video.id,
                      //     progress: videoCurrentTime,
                      //     status: "completed",
                      //   },
                      // });
                      // onBoardData.results[0].is_previewed = true;
                    }}
                  ></VideoStreaming>
                  <div className="flex justify-between gap-2 pr-[25px]">
                    <button
                      onClick={() => {
                        setPopup(false);
                      }}
                      className="text-green text-center bg-white w-[119px] h-[40px] rounded-full text-sm font-bold border border-green"
                    >
                      Close
                    </button>
                    {career?.is_completed ? (
                      <button
                        onClick={() => {
                          navigate(
                            "/user-careers/careers/career-insights/" +
                            career?.career_id
                          );
                        }}
                        className="text-white text-center bg-green w-[119px] h-[40px] rounded-full text-sm font-bold border border-green"
                      >
                        Insight
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          navigate("/user-careers/deeper-dive/" + career?.id);
                        }}
                        className="text-white text-center bg-green w-[119px] h-[40px] rounded-full text-sm font-bold border border-green"
                      >
                        Deeper Dive
                      </button>
                    )}
                  </div>
                </GlimpsePopup>
                <div className="p-2 sm:p-4">
                  <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
                    Completed Video Previews
                  </div>
                  <div className="flex flex-wrap items-center">
                    {status === "pending" && <Loading />}
                    {status === "success" && !deeperDiveList?.length && (
                      <Loading text="There are no completed video previews careers" />
                    )}
                    {deeperDiveList &&
                      deeperDiveList.map((item: IEndUserCareer) => {
                        return (
                          <div key={item.id} className="p-1 md:p-2">
                            <CareerItem
                              id={item.id}
                              icon_url={item.icon_url}
                              name={item.name}
                              pressed={() => {
                                // startDeeperDive(item);
                                startPreview(item);
                              }}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            {activeTab === CareerTypeEnum.deepdive && (
              <>
                <div className="p-2 sm:p-4">
                  <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
                    Complete
                  </div>
                  <div className="relative">
                    {isRefetching ? (
                      <Loading />
                    ) : (
                      <div className="flex flex-wrap items-center sm:py-4">
                        {!deeperDiveList?.filter((x) => x.is_completed)
                          ?.length && (
                            <span className="text-sm">
                              You haven’t completed any deeper dives yet.
                            </span>
                          )}
                        {deeperDiveList &&
                          deeperDiveList
                            .filter((x) => x.is_completed)
                            .map((item: IEndUserCareer) => {
                              return (
                                <div key={item.id} className="p-1 md:p-2 ">
                                  <CareerItem
                                    id={item.id}
                                    icon_url={item.icon_url}
                                    name={item.name}
                                    pressed={(id: string) =>
                                      startDeeperDive(item)
                                    }
                                  />
                                </div>
                              );
                            })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="p-2 sm:p-4">
                  <div className="pb-4 text-xl font-bold leading-6 text-headerBlue">
                    Incomplete
                  </div>
                  {isRefetching ? (
                    <Loading />
                  ) : (
                    <div className="flex flex-wrap items-center sm:py-4">
                      {!deeperDiveList?.length && (
                        <span className="text-sm">
                          There are no incomplete career
                        </span>
                      )}
                      {deeperDiveList &&
                        deeperDiveList
                          .filter((x) => !x.is_completed)
                          .map((item: IEndUserCareer) => {
                            return (
                              <div key={item.id} className="p-1 md:p-2 ">
                                <CareerItem
                                  id={item.id}
                                  icon_url={item.icon_url}
                                  name={item.name}
                                  pressed={(id: string) =>
                                    navigate(
                                      "/user-careers/deeper-dive/" + item.id
                                    )
                                  }
                                />
                              </div>
                            );
                          })}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <div className="order-1 lg:order-2">
          <DeepDiveSideData />
          {/* {activeTab === CareerTypeEnum.preview && previewData ? (
            <PreviewSideData
              closer_look_progress={previewData?.closer_look_progress}
              my_career_ig_level={previewData?.my_career_ig_level}
              top_three_careers={previewData?.top_three_careers}
              video_progress={previewData?.video_progress}
            />
          ) : (
            <DeepDiveSideData />
          )} */}
        </div>
      </div>
    </>
  );
};

export default UserCareersScreen;
