import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ImagePicker from "components/image-picker/image-picker.component";
import GlimpseInputBox from "components/input/formik-input";
import { displayError, displaySuccess } from "components/Toast/toast.component";
import { Formik, Form } from "formik";
import { FileType } from "interfaces/MediaPickerInterface";
import {
  IPartnerDataUpdate,
  IPartnerUserData,
} from "interfaces/user.interface";
import React, { useEffect, useState } from "react";
import { getPartnerData, updatePartnerData } from "services/setting.service";
import * as Yup from "yup";

const PartnerDataSetting: React.FC = () => {
  const [partnerUserData, setPartnerUserData] = useState<IPartnerUserData>();
  const queryClient = useQueryClient()
  const { data: partnerUserInfo } = useQuery({
    queryKey: ["partnerUserInfo"],
    queryFn: getPartnerData,
    refetchOnWindowFocus: false,
    retry: 0,
  });

  useEffect(() => {
    if (partnerUserInfo) {
      setPartnerUserData(partnerUserInfo);
    }
  }, [partnerUserInfo]);

  const partnerValidationSchema = Yup.object().shape({
    profile: Yup.object().shape({
      contact_person: Yup.string().required("Contact Name is required"),
    }),
  });
  const handlePartnerInfo = useMutation({
    mutationFn: (data: FormData) => updatePartnerData(data),
    retry: 0,
    onSuccess: () => {
      displaySuccess("Partner contact name changed successfully.");
      queryClient.invalidateQueries({ queryKey: ['partnerUserInfo'] })
    },
    onError: () => {
      displayError("Could not update partner contact Name.");
    },
  });

  const handlePartnerInfoSubmit = (values: IPartnerUserData) => {


    const payload = new FormData();
    if (values.avatar) {
      payload.append("avatar", values.avatar);
    }
    payload.append("contact_person", values.profile.contact_person);
    handlePartnerInfo.mutateAsync(payload);
  };
  return (
    <div className="py-4 form">
      {partnerUserData && (
        <Formik
          initialValues={partnerUserData}
          validationSchema={partnerValidationSchema}
          onSubmit={handlePartnerInfoSubmit}
        >
          {({ isSubmitting, setFieldValue, errors, values }) => (
            <Form>
                   
              <div className="flex flex-col gap-2">
              <ImagePicker
                id="icon"
                name="icon"
                media={values.profile.avatar}
                className="w-[62px] h-[62px] relative"
                svgPenClassName={`relative ${values.profile.avatar ? "-bottom-[10px]" : "bottom-2"
                  } right-[0px]`}
                svgWidth={27}
                svgHeight={27}
                order={1}
                enableAutoUpload={false}
                onFileUploaded={(e: FileType) => {
                  setFieldValue("avatar", e);
                }}
              />
                <GlimpseInputBox
                  disabled
                  id="name"
                  name="profile.name"
                  placeholder="Organization"
                  as="input"
                  showLabel={true}
                  label="Organization"
                />
                <GlimpseInputBox
                  id="contact_Person"
                  name="profile.contact_person"
                  placeholder="Contact Name"
                  as="input"
                  showLabel={true}
                  label="Contact Name"
                  maxLength={50}
                />
                <GlimpseInputBox
                  disabled
                  id="email"
                  name="email"
                  placeholder="Email"
                  as="input"
                  showLabel={true}
                  label="Email"
                />
              </div>
              <div className="flex pt-4">
                <button
                  type="submit"
                  className="text-white text-center bg-green gap-3 w-[89px] h-[32px] rounded-full text-sm font-normal leading-5"
                >
                  Save
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default PartnerDataSetting;
